<!-- Created by henian.xu on 2018/9/5. -->

<template>
    <page ref="page">
        <div
            class="second-header"
            slot="secondHeader"
        >
            <el-form
                ref="saveForm"
                class="npa-b"
                size="small"
                :inline="true"
                :model="saveFormModel"
                :rules="saveFormRules"
            >
                <el-form-item
                    prop="pageName"
                    label="页面名称"
                    v-if="pageType === 0 || pageType === 1"
                >
                    <el-input v-model="saveFormModel.pageName"/>
                </el-form-item>
                <el-form-item
                    label="是否启用"
                    v-if="pageType === 0 || pageType === 1 || pageType === 2"
                >
                    <el-switch v-model="saveFormModel.isAvailable"/>
                </el-form-item>
            </el-form>
            <el-button
                size="small"
                type="primary"
                @click="onSave(false)"
            >
                保存
            </el-button>
            <el-button
                size="small"
                type="success"
                @click="onSave(true)"
            >
                保存并预览
            </el-button>
            <el-button
                size="small"
                @click="onRestore"
            >
                还原初始状态
            </el-button>
        </div>

        <PageDecorator
            ref="pageDecorator"
            :json="decorationJson"
            :available-components="availableComponents"
        />
        <div class="ma-t"><el-table
            stripe
            border
            size="small"
            :data="noticeList"
            style="width: 100%"
        >
            <el-table-column
                prop="pageName"
                label="小程序功能"
                min-width="100"
            />
            <el-table-column
                prop="pageUrl"
                label="链接"
                min-width="100"
            />
            <el-table-column
                prop="example"
                label="示例"
                min-width="100"
            />
        </el-table></div>
        <el-dialog
            title="扫一扫"
            width="300px"
            :visible.sync="QRCodeDialog.visible"
        >
            <img
                :src="qrcodeUrl"
                width="100%"
            >
        </el-dialog>
    </page>
</template>

<script>
import { Utils } from 'vmf';

export default {
    name: 'DecorationEdit',
    data() {
        return {
            qrcodeUrl: '',
            saveFormModel: {
                id: '',
                pageName: '',
                isAvailable: false,
            },
            decorationJson: undefined,
            saveFormRules: {
                pageName: {
                    required: true,
                    message: '不可为空',
                },
            },
            QRCodeDialog: {
                visible: false,
            },
            availableComponents: ['Divider', 'Toolbar', 'Ad', 'AdCube', 'MagicCube', 'RichText', 'Notification'],
            noticeList: [
                { pageName: '首页', pageUrl: '/pages/index', example: '/pages/index' },
                {
                    pageName: '动态列表',
                    pageUrl: 'COMM://ROUTER/?reLaunch=1&path=%2Fpages%2Findex%3FtabKey%3DdynamicStatePage',
                    example: 'COMM://ROUTER/?reLaunch=1&path=%2Fpages%2Findex%3FtabKey%3DdynamicStatePage',
                },
                {
                    pageName: '动态详情',
                    pageUrl: '/pages/pkg1/pages/dynamicState/detail?id={ID}',
                    example: '/pages/pkg1/pages/dynamicState/detail?id=18',
                },
                {
                    pageName: '通知公告列表',
                    pageUrl: '/pages/pkg1/pages/notice/list',
                    example: '/pages/pkg1/pages/notice/list',
                },
                {
                    pageName: '通知公告详情',
                    pageUrl: '/pages/pkg1/pages/notice/detail?noticeId={ID}',
                    example: '/pages/pkg1/pages/notice/detail?noticeId=6',
                },
                {
                    pageName: '党员论坛首页',
                    pageUrl: '/pages/pkg1/pages/partyMemberForum/index',
                    example: '/pages/pkg1/pages/partyMemberForum/index',
                },
                {
                    pageName: '党员论坛详情',
                    pageUrl: '/pages/pkg1/pages/partyMemberForum/detail?postId={ID}',
                    example: '/pages/pkg1/pages/partyMemberForum/detail?postId=7',
                },
                {
                    pageName: '论坛个人中心',
                    pageUrl: '/pages/pkg1/pages/partyMemberForum/user',
                    example: '/pages/pkg1/pages/partyMemberForum/user',
                },
                {
                    pageName: '积分排行',
                    pageUrl: '/pages/pkg1/pages/pointsRanking/index',
                    example: '/pages/pkg1/pages/pointsRanking/index',
                },
                {
                    pageName: '随手拍',
                    pageUrl: '/pages/pkg1/pages/randomlySnapPicture/list',
                    example: '/pages/pkg1/pages/randomlySnapPicture/list',
                },
                {
                    pageName: '书记信箱',
                    pageUrl: '/pages/pkg1/pages/secretaryMailbox/list',
                    example: '/pages/pkg1/pages/secretaryMailbox/list',
                },
                {
                    pageName: '建言献策',
                    pageUrl: '/pages/pkg1/pages/suggest/list',
                    example: '/pages/pkg1/pages/suggest/list',
                },
                {
                    pageName: '匿名举报',
                    pageUrl: '/pages/pkg1/pages/anonymousReport/list',
                    example: '/pages/pkg1/pages/anonymousReport/list',
                },
                {
                    pageName: '课程列表',
                    pageUrl: '/pages/pkg1/pages/course/list',
                    example: '/pages/pkg1/pages/course/list',
                },
                {
                    pageName: '课程详情',
                    pageUrl: '/pages/pkg1/pages/course/detail?courseId={ID}',
                    example: '/pages/pkg1/pages/course/detail?courseId=3',
                },
                {
                    pageName: '组织活动列表',
                    pageUrl: '/pages/pkg1/pages/activity/list',
                    example: '/pages/pkg1/pages/activity/list',
                },
                {
                    pageName: '组织活动详情',
                    pageUrl: '/pages/pkg1/pages/activity/detail?activityId={ID}',
                    example: '/pages/pkg1/pages/activity/detail?activityId=7',
                },
                {
                    pageName: '我的组织活动报名',
                    pageUrl: '/pages/pkg1/pages/activity/user',
                    example: '/pages/pkg1/pages/activity/user',
                },
                {
                    pageName: '志愿服务列表',
                    pageUrl: '/pages/pkg1/pages/volunteerServe/list',
                    example: '/pages/pkg1/pages/volunteerServe/list',
                },
                {
                    pageName: '志愿服务详情',
                    pageUrl: '/pages/pkg1/pages/volunteerServe/detail?activityId={ID}',
                    example: '/pages/pkg1/pages/volunteerServe/detail?activityId=10',
                },
                {
                    pageName: '我的志愿服务报名',
                    pageUrl: '/pages/pkg1/pages/volunteerServe/user',
                    example: '/pages/pkg1/pages/volunteerServe/user',
                },
                {
                    pageName: '考试列表',
                    pageUrl: '/pages/pkg1/pages/exam/list',
                    example: '/pages/pkg1/pages/exam/list',
                },
                {
                    pageName: '考试详情',
                    pageUrl: '/pages/pkg1/pages/exam/detail?examId={ID}',
                    example: '/pages/pkg1/pages/exam/detail?examId=5',
                },
                {
                    pageName: '测试列表',
                    pageUrl: '/pages/pkg1/pages/exam/list?tabKey=2',
                    example: '/pages/pkg1/pages/exam/list?tabKey=2',
                },
                {
                    pageName: '测试详情',
                    pageUrl: '/pages/pkg1/pages/exam/detail?examId={ID}',
                    example: '/pages/pkg1/pages/exam/detail?examId=4',
                },
            ],
        };
    },
    computed: {
        pageType() {
            return +this.$route.query.pageType;
        },
        adPageType() {
            return this.$route.query.adPageType;
        },
        distributorLevelId() {
            return this.$route.query.distributorLevelId;
        },
    },
    methods: {
        onSave(preview) {
            this.$refs.saveForm.validate().then(() => {
                this.$api.Sv.ServantDecoration.saveJsonData({
                    ...this.saveFormModel,
                    pageType: this.pageType,
                    JsonData: this.$refs.pageDecorator.getDecorationJson(),
                    // 在这里传剩余的参数如下
                    adPageType: this.adPageType,
                    distributorLevelId: this.distributorLevelId,
                }).then(json => {
                    const res = json.data;
                    this.saveFormModel.id = res.data.id;
                    if (preview) {
                        console.log(res);
                        Utils.QRCode.toDataURL(
                            res.data.buyerUrl,
                            {
                                margin: 2,
                                scale: 30,
                                errorCorrectionLevel: 'H',
                            },
                            (error, url) => {
                                if (error) {
                                    throw new Error(error);
                                }
                                this.qrcodeUrl = url;
                                this.QRCodeDialog.visible = true;
                            },
                        );
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                    }
                });
            });
        },
        onRestore() {
            this.$refs.pageDecorator.restoreInitial();
        },
    },
    created() {
        if (this.$route.params.id) {
            this.saveFormModel.id = this.$route.params.id;
            this.$api.Sv.ServantDecoration.getJsonData({ id: this.saveFormModel.id }).then(json => {
                const res = json.data.data || {};
                const decoration = res.decoration || {};
                this.decorationJson = decoration.pageContent;
                const { saveFormModel } = this;
                Object.keys(saveFormModel).forEach(key => {
                    saveFormModel[key] = decoration[key] || saveFormModel[key];
                });
            });
        }
    },
};
</script>

<style lang="scss">
.decoration-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    > .ctrl {
        /*position: fixed;*/
        /*right: 18px;*/
        /*width: 400px;*/
    }
}
</style>
